<template>
    <ul id="element-settings" class="uk-switcher uk-margin">
      <li class="uk-active">
        <div class="uk-form-stacked">
          <div class="uk-width-1-1">
            <div class="uk-margin">
              <label class="uk-form-label">Inhalt</label>
              <div class="uk-form-controls">
                <textarea class="uk-textarea" rows="10" v-model="form.content"></textarea>
              </div>
            </div>
            <div class="uk-margin">
              <label class="uk-form-label">Link</label>
              <div class="uk-form-controls">
                <input type="text" class="uk-input" v-model="form.link" />
                <p class="uk-text-small uk-text-muted uk-margin-small">Link einfügen</p>
                <label>
                  <input
                    type="checkbox"
                    class="uk-checkbox"
                    v-model="form.target"
                    :true-value="'_blank'"
                    :false-value="'_self'"
                    :disabled="!form.link"
                  /> Link in einem neuen Fenster öffnen
                </label>
              </div>
            </div>
            <div class="uk-margin">
              <div class="uk-grid uk-child-width-1-4@l uk-child-width-1-3@m" uk-grid>
                <div>
                  <label class="uk-form-label">Style</label>
                  <select class="uk-select" v-model="form.style">
                    <option value>None</option>
                    <option value="heading-2xlarge">2Xlarge</option>
                    <option value="heading-xlarge">XLarge</option>
                    <option value="heading-large">Large</option>
                    <option value="heading-medium">Medium</option>
                    <option value="heading-small">Small</option>
                    <option value="h1">H1</option>
                    <option value="h2">H2</option>
                    <option value="h3">H3</option>
                    <option value="h4">H4</option>
                    <option value="h5">H5</option>
                    <option value="h6">H6</option>
                  </select>
                </div>
                <div>
                  <label class="uk-form-label">Dekoration</label>
                  <select class="uk-select" v-model="form.decoration">
                    <option value>None</option>
                    <option value="divider">Divider</option>
                    <option value="bullet">Bullet</option>
                    <option value="line">Line</option>
                  </select>
                </div>
                <div>
                  <label class="uk-form-label">HTML Element</label>
                  <select class="uk-select" v-model="form.htmlElement">
                    <option value="h1">H1</option>
                    <option value="h2">H2</option>
                    <option value="h3">H3</option>
                    <option value="h4">H4</option>
                    <option value="h5">H5</option>
                    <option value="h6">H6</option>
                    <option value="div">div</option>
                  </select>
                </div>
                <div>
                  <label class="uk-form-label">Farbe</label>
                  <select class="uk-select" v-model="form.color">
                    <option value>Keine</option>
                    <option value="muted">Gedämpft</option>
                    <option value="emphasis">Betont</option>
                    <option value="primary">Primär</option>
                    <option value="success">Erfolgt</option>
                    <option value="warning">Warnung</option>
                    <option value="danger">Achtung</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li>
        <div class="uk-width-1-1 uk-margin">
          <div>
            <general-settings :form="form" />
          </div>
        </div>
      </li>
    </ul>
</template>

<script>
import GeneralSettings from './../GeneralSettings'
export default {
  props: {
    data: {
      required: true,
      type: Object
    }
  },
  components: {
    GeneralSettings
  },
  data () {
    return {
      form: {
        content: '',
        link: '',
        target: ''
      },
      showSettings: false
    }
  },
  mounted () {
    this.form = this.data
  }
}
</script>
