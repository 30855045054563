<template>
  <div class="uk-grid uk-child-width-1-1" uk-grid>
    <div>
      <div class="uk-h5 uk-text-uppercase">Allgemein</div>
      <div>
        <div class="uk-grid uk-child-width-1-2@m" uk-grid>
          <div>
            <div class="uk-grid uk-child-width-1-1@m" uk-grid>
              <div>
                <label class="uk-form-label">Position</label>
                <select class="uk-select" v-model="form.position">
                  <option value>Statisch</option>
                  <option value="relative">Relativ</option>
                  <option value="absolute">Absolut</option>
                </select>
              </div>
              <div>
                <label class="uk-form-label">Links</label>
                <div class="uk-grid-small uk-flex-middle uk-grid" uk-grid>
                  <div class="uk-width-expand">
                    <input
                      type="range"
                      min="-600"
                      max="600"
                      step="10"
                      class="uk-range"
                      :disabled="!form.position"
                      v-model="form.position_left"
                    />
                  </div>
                  <div class="uk-width-auto">
                    <input
                      type="text"
                      class="uk-input uk-form-width-xsmall"
                      v-model="form.position_left"
                      :disabled="!form.position"
                    />
                  </div>
                </div>
              </div>
              <div class="uk-margin-small">
                <label class="uk-form-label">Rechts</label>
                <div class="uk-grid-small uk-flex-middle uk-grid" uk-grid>
                  <div class="uk-width-expand">
                    <input
                      type="range"
                      min="-600"
                      max="600"
                      step="10"
                      class="uk-range"
                      :disabled="!form.position"
                      v-model="form.position_right"
                    />
                  </div>
                  <div class="uk-width-auto">
                    <input
                      type="text"
                      class="uk-input uk-form-width-xsmall"
                      v-model="form.position_right"
                      :disabled="!form.position"
                    />
                  </div>
                </div>
              </div>
              <div class="uk-margin-small">
                <label class="uk-form-label">Oben</label>
                <div class="uk-grid-small uk-flex-middle uk-grid" uk-grid>
                  <div class="uk-width-expand">
                    <input
                      type="range"
                      min="-600"
                      max="600"
                      step="10"
                      class="uk-range"
                      :disabled="!form.position"
                      v-model="form.position_top"
                    />
                  </div>
                  <div class="uk-width-auto">
                    <input
                      type="text"
                      class="uk-input uk-form-width-xsmall"
                      v-model="form.position_top"
                      :disabled="!form.position"
                    />
                  </div>
                </div>
              </div>
              <div class="uk-margin-small">
                <label class="uk-form-label">Unten</label>
                <div class="uk-grid-small uk-flex-middle uk-grid" uk-grid>
                  <div class="uk-width-expand">
                    <input
                      type="range"
                      min="-600"
                      max="600"
                      step="10"
                      class="uk-range"
                      :disabled="!form.position"
                      v-model="form.position_bottom"
                    />
                  </div>
                  <div class="uk-width-auto">
                    <input
                      type="text"
                      class="uk-input uk-form-width-xsmall"
                      v-model="form.position_bottom"
                      :disabled="!form.position"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="uk-grid uk-grid-small uk-child-width-1-1" uk-grid>
              <div>
                <label class="uk-form-label">Text Ausrichtung</label>
                <select class="uk-select" v-model="form.text_align">
                  <option value>Keine</option>
                  <option value="left">Links</option>
                  <option value="center">Mittig</option>
                  <option value="right">Rechts</option>
                </select>
              </div>
              <div>
                <label class="uk-form-label">Text Ausrichtung Breakpoint</label>
                <select class="uk-select" v-model="form.text_align_breakpoint">
                  <option value>Immer</option>
                  <option value="s">Klein (Phone Landscape)</option>
                  <option value="m">Medium (Tablet Landscape)</option>
                  <option value="l">Groß (Desktop)</option>
                  <option value="xl">Sehr groß (Large Screens)</option>
                </select>
              </div>
              <div>
                <label class="uk-form-label">Sichtbarkeit</label>
                <select class="uk-select" v-model="form.visibility">
                  <option value>Immer</option>
                  <option value="s">Klein (Phone Landscape)</option>
                  <option value="m">Medium (Tablet Landscape)</option>
                  <option value="l">Groß (Desktop)</option>
                  <option value="xl">X-Large (Large Screens)</option>
                </select>
              </div>
              <div>
                 <label>
                  <input
                    class="uk-checkbox"
                    type="checkbox"
                    v-model="form.scrollspy"
                  /> Aktiviere Scrollspy
                </label>
              </div>
              <div v-if="form.scrollspy">
                <div class="uk-margin">
                  <label class="uk-form-label">Scrollspy Animation</label>
                  <select class="uk-select" v-model="form.scrollspy_animation">
                    <option value="fade">fade</option>
                    <option value="slide-top">slide-top</option>
                    <option value="slide-bottom">slide-bottom</option>
                    <option value="slide-left">slide-left</option>
                    <option value="slide-right">slide-right</option>
                    <option value="scale-up">scale-up</option>
                    <option value="scale-down">scale-down</option>
                    <option value="slide-top-small">slide-top-small</option>
                    <option value="slide-bottom-small">slide-bottom-small</option>
                    <option value="slide-left-small">slide-left-small</option>
                    <option value="slide-right-small">slide-right-small</option>
                    <option value="slide-top-medium">slide-top-medium</option>
                    <option value="slide-bottom-medium">slide-bottom-medium</option>
                    <option value="slide-left-medium">slide-left-medium</option>
                    <option value="slide-right-medium">slide-right-medium</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    form: {
      type: Object,
      required: true
    }
  }
};
</script>
